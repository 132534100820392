<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">
            <!-- Brand logo-->
            <b-link class="brand-logo">
                <vuexy-logo />

                <h2 class="brand-text text-primary ml-1">
                    {{ appName }}
                </h2>
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
                <div
                    class="w-100 d-lg-flex align-items-center justify-content-center px-5"
                >
                    <b-img :src="imgUrl" fluid :alt="$t(`pages.${pagename}.pagetitle`)" />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Reset password-->
            <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
                        {{ $t(`pages.${pagename}.msg1`) }}
                    </b-card-title>
                    <b-card-text class="mb-2">
                        {{ $t(`pages.${pagename}.msg2`) }}
                    </b-card-text>

                    <!-- form -->
                    <validation-observer ref="simpleRules">
                        <b-form class="auth-reset-password-form mt-2">
                            <!-- password -->
                            <b-form-group
                                :label="$t(`pages.${pagename}.msg3`)"
                                label-for="reset-password-new"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Password"
                                    vid="Password"
                                    rules="required|min:4"
                                >
                                    <b-input-group
                                        class="input-group-merge"
                                        :class="errors.length > 0 ? 'is-invalid' : null"
                                    >
                                        <b-form-input
                                            id="reset-password-new"
                                            v-model="password"
                                            :type="password1FieldType"
                                            :state="errors.length > 0 ? false : null"
                                            class="form-control-merge"
                                            name="reset-password-new"
                                            placeholder="············"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                class="cursor-pointer"
                                                :icon="password1ToggleIcon"
                                                @click="togglePassword1Visibility"
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- confirm password -->
                            <b-form-group
                                label-for="reset-password-confirm"
                                :label="$t(`pages.${pagename}.msg4`)"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Confirm Password"
                                    rules="required|confirmed:Password"
                                >
                                    <b-input-group
                                        class="input-group-merge"
                                        :class="errors.length > 0 ? 'is-invalid' : null"
                                    >
                                        <b-form-input
                                            id="reset-password-confirm"
                                            v-model="cPassword"
                                            :type="password2FieldType"
                                            class="form-control-merge"
                                            :state="errors.length > 0 ? false : null"
                                            name="reset-password-confirm"
                                            placeholder="············"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                class="cursor-pointer"
                                                :icon="password2ToggleIcon"
                                                @click="togglePassword2Visibility"
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- submit button -->
                            <b-button
                                block
                                type="submit"
                                variant="primary"
                                @click.prevent="validationForm"
                            >
                                {{ $t(`pages.${pagename}.msg5`) }}
                            </b-button>
                        </b-form>
                    </validation-observer>

                    <p class="text-center mt-2">
                        <b-link :to="{ name: 'Login', params: {} }">
                            <feather-icon icon="ChevronLeftIcon" />
                            {{ $t(`pages.${pagename}.msg6`) }}
                        </b-link>
                    </p>
                </b-col>
            </b-col>
            <!-- /Reset password-->
        </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton, BImg,
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import { Tientech_getPageRandomNumber } from '@/libs/tientech/api'

export default {
  setup() {
    // App Name
    const {
      appName, appLogoImage, companyName, companyUrl, author,
    } = $themeConfig.webConfigs[document.location.hostname] ? $themeConfig.webConfigs[document.location.hostname] : $themeConfig.app
    return {
      pagename: 'resetpassword',
      appName,
      appLogoImage,
      companyName,
      companyUrl,
      author,
    }
  },
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BImg,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      cPassword: '',
      password: '',
      token: '',
      sideImg: require('@/assets/images/pages/reset-password-v2.svg'),
      random_number: 0,
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    try {
      // let vuethis = this
      this.initialize()
      console.log()
    } catch (error) {
      console.log(error)
    }
  },
  created() {
    this.random_number = Tientech_getPageRandomNumber()
  },
  methods: {
    initialize() {
      try {
        // initialize
        if (this.$route.params !== undefined) {
          if (this.$route.params.token !== undefined) {
            this.token = this.$route.params.token
          }
        }
        if (this.token === '') {
          this.$swal({
            icon: 'error',
            title: this.$t(`pages.${this.pagename}.pagetitle`),
            text: this.$t(`pages.${this.pagename}.msg8`),
            showConfirmButton: true,
            timer: 3000,
            buttonsStyling: true,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          }).then(result => {
            this.$router.push({ name: 'ForgotPassword', params: {} }).catch(error => {
              // console.warn(error.message)
            })
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        try {
          const vuethis = this
          if (!success || this.token === '') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('error_common.input_error'),
                icon: 'EditIcon',
                variant: 'danger',
              },
            }, {
              position: 'top-center',
            })
            return
          }
          const obj = {
            password: this.password,
            token: this.token,
          }
          this.$socket.emit('accountcloud_forgot_resetpassword',
            {
              data: obj,
              type: 'account',
              callback_params: `${this.pagename}_${this.random_number}`,
            }, params => {
              try {
                if (params.type === 'error') {
                  let errormsg = vuethis.$t('error_common.unknown')
                  if (params.msg === 'account_error_userisnonexistent') {
                    errormsg = vuethis.$t('error_common.account_error_userisnonexistent')
                  }
                  if (params.msg === 'token is undefined') {
                    errormsg = vuethis.$t(`pages.${vuethis.pagename}.msg8`)
                  }
                  if (params.msg === 'token is undefined' || params.msg === 'token is error' || params.msg === 'token is expired') {
                    errormsg = vuethis.$t(`pages.${vuethis.pagename}.msg8`)
                  }
                  this.$swal({
                    icon: 'error',
                    title: errormsg,
                    showConfirmButton: true,
                    timer: 3000,
                    buttonsStyling: true,
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                  })
                } else {
                  this.$swal({
                    icon: 'success',
                    title: vuethis.$t('common.modify_success'),
                    text: vuethis.$t(`pages.${vuethis.pagename}.msg7`),
                    showConfirmButton: true,
                    timer: 5000,
                    buttonsStyling: true,
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                  }).then(result => {
                    this.$router.push({ name: 'Login', params: {} }).catch(error => {
                      // console.warn(error.message)
                    })
                  })
                }
              } catch (error) {
                console.log(error)
              }
            })
        } catch (error) {
          console.log(error)
        }
      })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
